module.exports = [{
      plugin: require('../../../node_modules/.pnpm/@sentry+gatsby@6.19.7_gatsby@4.15.2+react@17.0.2/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.15.2_3j5kiqpkwml5zzfuaom7hdylau/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
