exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beta-tsx": () => import("./../../../src/pages/beta.tsx" /* webpackChunkName: "component---src-pages-beta-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-new-final-final-v-2-realfinal-tsx": () => import("./../../../src/pages/new-final-FINAL-v2-REALFINAL.tsx" /* webpackChunkName: "component---src-pages-new-final-final-v-2-realfinal-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

